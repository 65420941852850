// ** Third Party Components
import styled from "styled-components";

// ** Custom Data
import { additionalContainersPosition } from "./additionalContainersPosition";

// #####################################################

export const StyledPrivateDataIconContainer = styled("div")`
	color: ${({ theme: { colors } }) => colors.black};
	position: absolute;
	right: ${({ size }) =>
		Number.isFinite(size) ? size : additionalContainersPosition[size]}px;
	bottom: ${({ size }) =>
		Number.isFinite(size) ? size : additionalContainersPosition[size]}px;
	line-height: 0;
`;

// #####################################################
