import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "components/global/Icon";
import { breakpoints } from "styles/grid";

// ** SVG Static Imports
import { ReactComponent as SolidLock } from "resources/icons/solid/lock.svg";

const iconSizes = {
	xlarge: {
		size: 1.2,
		shadow: 1.5,
		left: 25,
		top: 0,
	},
	large: {
		size: 1.1,
		shadow: 1.5,
		left: 22,
		top: 0,
	},
	mlarge: {
		size: 1,
		shadow: 1.5,
		left: 22,
		top: 0,
	},
	medium: {
		size: 0.9,
		shadow: 1.5,
		left: 22,
		top: -1,
	},
	base: {
		size: 0.8,
		shadow: 1.5,
		left: 17,
		top: -0.5,
	},
	small: {
		size: 0.65,
		shadow: 0.5,
		left: 13,
		top: 0,
	},
	xsmall: {
		size: 0.55,
		shadow: 0.5,
		left: 12,
		top: 0,
	},
	xxsmall: {
		size: 0.55,
		shadow: 0.5,
		left: 12,
		top: 0,
	},
	default: {
		shadow: 1.5,
		left: 24,
		top: 0,
	},
};

const IconStyled = styled(Icon)`
	position: relative;
	z-index: 2;
	right: ${({ size }) =>
		Number.isFinite(size)
			? `${iconSizes.default.left}px`
			: `${iconSizes[size].left}px`};
	top: ${({ size }) =>
		Number.isFinite(size)
			? `${iconSizes.default.top}px`
			: `${iconSizes[size].top}px`};
	margin-bottom: 2px;

	svg {
		color: ${({ theme }) => theme.colors.blackAlways};

		filter: ${({ size }) =>
			Number.isFinite(size)
				? `drop-shadow(${iconSizes.default.shadow}px -${iconSizes.default.shadow}px 0px white) 
			drop-shadow(-${iconSizes.default.shadow}px .5px 0px white) 
			drop-shadow(${iconSizes.default.shadow}px ${iconSizes.default.shadow}px 0px white) 
			drop-shadow(.5px ${iconSizes.default.shadow}px 0px white)`
				: `drop-shadow(${iconSizes[size].shadow}px -${iconSizes[size].shadow}px 0px white) 
			drop-shadow(-${iconSizes[size].shadow}px .5px 0px white) 
			drop-shadow(${iconSizes[size].shadow}px ${iconSizes[size].shadow}px 0px white) 
			drop-shadow(.5px ${iconSizes[size].shadow}px 0px white)`};
	}

	@media (max-width: ${breakpoints.phone - 1}px) {
		svg {
			filter: drop-shadow(white 2px -2px 0px)
				drop-shadow(white -2.5px -0.5px 0px)
				drop-shadow(white 0.5px 2px 0px)
				drop-shadow(white 0.5px 0.5px 0px);
		}
	}
`;

const IconContainer = styled("div")`
	position: relative;
	display: inline-block;
	font-size: ${({ size }) =>
		Number.isFinite(size) ? size : `${iconSizes[size]?.size}`}rem;
`;

const PrivateDataIcon = ({ size, privateData, ...props }) => {
	return privateData ? (
		<IconContainer size={size == null ? false : size} {...props}>
			<IconStyled
				svgIconComponent={SolidLock}
				size={size == null ? false : size}
			>
				lock
			</IconStyled>
		</IconContainer>
	) : null;
};

PrivateDataIcon.defaultProps = {
	privateData: false,
	size: "medium",
};

PrivateDataIcon.propTypes = {
	privateData: PropTypes.bool,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PrivateDataIcon.displayName = "PrivateDataIcon";

export default PrivateDataIcon;
