/* eslint-disable no-param-reassign */

// Capitalize First Letter Of String
export default str => {
	str = String(str).toString();
	str = str.replace(
		/\w\S*/g,
		txt => txt.charAt(0).toUpperCase() + txt.substr(1)
	);
	return str;
};
