// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Data
import { additionalContainersPosition } from "./additionalContainersPosition";

// #####################################################

export const StyledOnlineCircleContainer = styled("div")`
	position: absolute;
	right: ${({ size }) =>
		Number.isFinite(size) ? size : additionalContainersPosition[size]}px;
	bottom: ${({ size }) =>
		Number.isFinite(size) ? size : additionalContainersPosition[size]}px;
	line-height: 0;

	${({ $customOnlineCirclePosition }) => {
		return (
			$customOnlineCirclePosition &&
			css`
				bottom: ${$customOnlineCirclePosition?.bottom};
				right: ${$customOnlineCirclePosition?.right};
			`
		);
	}}
`;

// #####################################################
