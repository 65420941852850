export const additionalContainersPosition = {
	xlarge: 14,
	large: 12,
	mlarge: 11,
	medium: 9,
	base: 5,
	small: 1,
	xsmall: 0,
	xxsmall: 0,
};
