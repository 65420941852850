// ** React Imports
import { useCallback } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import PropTypes from "prop-types";

// ** Custom Components
import OnlineCircle from "../OnlineCircle";
import OnlineTag from "../OnlineTag";
import PrivateDataIcon from "../PrivateDataIcon";
import Avatar, { avatarSizes } from "../../layout/Avatar";

// ** Utils & Helpers
import generatePath from "utils/generatePath";
import toTitleCase from "utils/toTitleCase";

// ** Routes
import routes from "containers";

// ** Styled Components
import {
	StyledPrivateDataIconContainer,
	StyledOnlineCircleContainer,
	StyledOnlineTagContainer,
} from "./styles";

// #####################################################

const UserAvatar = ({
	size,
	src,
	login,
	online,
	privateData,
	status,
	border,
	borderSize,
	tag,
	link,
	scrollForce,
	closeModal,
	userId,
	noOnlineCircle = false,
	customBorderRadius,
	customBoxShadow,
	noBorder,
	customOnlineCirclePosition,
	triggerOnce,
	alt,
	...rest
}) => {
	const currentLoggedUser = useSelector((state) => state.global.user);

	const isCurrentLoggedUser = currentLoggedUser.id === userId;

	const correctOnline = isCurrentLoggedUser
		? currentLoggedUser.online
		: online;

	const correctStatus = isCurrentLoggedUser
		? currentLoggedUser.profileStatus
		: status;

	const altFinal = toTitleCase(alt || `${login}`.trim() || "");

	// NOTE(Przemek): Lepiej to przenieść do layout/Avatar.jsx?
	if (link && !rest.to && login) {
		rest.to = generatePath(routes.user.path, {
			login: login?.toLowerCase(),
		});
	}

	// ** Hook - useCallback - Funkcja renderujasta status użytkownika
	// ** oraz oznaczenie o posiadaniu prywatnych treści
	const renderAdditional = useCallback(() => {
		if (correctOnline || privateData) {
			return (
				<>
					<StyledPrivateDataIconContainer size={size}>
						<PrivateDataIcon
							privateData={privateData}
							size={size}
						/>
					</StyledPrivateDataIconContainer>
					{correctStatus !== 4 && !noOnlineCircle && (
						<StyledOnlineCircleContainer
							style={{ marginBottom: "1px", marginRight: "1px" }}
							size={size}
							$customOnlineCirclePosition={
								customOnlineCirclePosition
							}
						>
							<OnlineCircle
								online={correctOnline}
								status={correctStatus}
								userLogin={login}
								size={size}
								isBigBorder={rest.isBigBorder}
							/>
						</StyledOnlineCircleContainer>
					)}
					{tag &&
					correctOnline &&
					correctStatus !== 4 &&
					!noOnlineCircle ? (
						<StyledOnlineTagContainer size={size}>
							<OnlineTag
								online={correctOnline}
								size={size}
								status={correctStatus}
								border={border}
							/>
						</StyledOnlineTagContainer>
					) : null}
				</>
			);
		}
		return null;
	}, [correctOnline, size, privateData, correctStatus]);

	// #####################################################

	return (
		<Avatar
			size={size}
			src={src}
			alt={altFinal}
			borderSize={borderSize}
			additional={renderAdditional}
			border={border}
			scrollForce={scrollForce}
			closeModal={closeModal}
			customBorderRadius={customBorderRadius}
			customBoxShadow={customBoxShadow}
			noBorder={noBorder}
			triggerOnce={triggerOnce}
			{...rest}
		/>
	);
};

// #####################################################

UserAvatar.defaultProps = {
	size: "small",
	border: false,
	borderSize: false,
	tag: false,
};

UserAvatar.propTypes = {
	size: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.oneOf([...Object.keys(avatarSizes)]),
	]),
	border: PropTypes.bool,
	tag: PropTypes.bool,
};

UserAvatar.displayName = "UserAvatar";

export default UserAvatar;

// #####################################################
