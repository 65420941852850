import { useSelector } from "react-redux";
import styled, { css } from "styled-components";

const circleSizes = {
	xlarge: 18,
	large: 16,
	mlarge: 15,
	medium: 15,
	base: 12,
	small: 10,
	xsmall: 9,
	xxsmall: 9,
};

const borderSizes = {
	xlarge: 2,
	large: 2,
	mlarge: 2,
	medium: 2,
	base: 2,
	small: 1,
	xsmall: 1,
	xxsmall: 1,
};

const statusColor = ({ status, online, isMe }) => {
	if (isMe) {
		switch (status) {
			case 1:
				return online === 1 ? "#ffcb18" : "#52c522";
			case 2:
				return "#f54ae0";
			case 3:
				return "var(--dz-sys-icon-background-default)";
			case 4:
				return "transparent";
			default:
				return null;
		}
	} else if (status === 1 || online === 0) {
		switch (online) {
			case 1:
				return "#ffcb18";
			case 2:
				return "#52c522";
			default:
				return null;
		}
	} else {
		switch (status) {
			case 2:
				return "#f54ae0";
			case 3:
				return "var(--dz-sys-icon-background-default)";
			case 4:
				return "transparent";
			default:
				return null;
		}
	}
};

const OnlineCircleStyled = styled("div")`
	position: relative;
	top: 0;
	right: 0;
	z-index: 2;

	display: inline-block;
	background-color: ${({ online, status, isMe }) =>
		statusColor({ online, status, isMe })};
	border-radius: 50%;
	box-sizing: border-box;
`;

const OnlineCircleContainer = styled("div").attrs({
	role: "img",
})`
	border-radius: 50%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	width: ${({ size }) =>
		Number.isFinite(size)
			? size
			: `${circleSizes[size] + borderSizes[size]}`}px;
	height: ${({ size }) =>
		Number.isFinite(size)
			? size
			: `${circleSizes[size] + borderSizes[size]}`}px;

	${OnlineCircleStyled} {
		width: ${({ size }) =>
			Number.isFinite(size)
				? size
				: `${circleSizes[size] + borderSizes[size]}`}px;
		height: ${({ size }) =>
			Number.isFinite(size)
				? size
				: `${circleSizes[size] + borderSizes[size]}`}px;
		${({ size, isBigBorder }) =>
			css`
				border: ${isBigBorder ? 3 : borderSizes[size]}px solid
					${({ theme: { colors } }) => colors.whiteTrueAlways};
			`}

		${({ isStatus4 }) =>
			isStatus4 &&
			css`
				width: ${({ theme: { isDarkMode } }) =>
					isDarkMode ? "10px" : "8px"};
				height: ${({ theme: { isDarkMode } }) =>
					isDarkMode ? "10px" : "8px"};
				border-color: ${({ theme: { isDarkMode } }) =>
					isDarkMode ? "#a9a9a9" : "#8a8a8a"};
			`}
	}
`;

const OnlineCircle = ({
	size = "medium",
	online = 0,
	status,
	border = true,
	isBigBorder,
	isCurrentLoggedUser = false,
	userLogin = null,
	...props
}) => {
	const currentLoggedUserLogin = useSelector(
		(state) => state.global.user.login
	);
	const isMe = currentLoggedUserLogin === userLogin || isCurrentLoggedUser;

	return statusColor({ online, status, isMe }) ? (
		<OnlineCircleContainer
			size={size}
			isBigBorder={isBigBorder}
			isStatus4={status === 4}
			{...props}
		>
			<OnlineCircleStyled
				online={online}
				status={status}
				border={border}
				isMe={isMe}
			/>
		</OnlineCircleContainer>
	) : null;
};

OnlineCircle.displayName = "OnlineCircle";

export { borderSizes, circleSizes };
export default OnlineCircle;
