// ** Third Party Components
import styled from "styled-components";

// ** Custom Data
import { additionalContainersPosition } from "./additionalContainersPosition";

// #####################################################

const additionalTagPosition = {
	xlarge: 124,
	large: 109,
	medium: 94,
};

export const StyledOnlineTagContainer = styled("div")`
	position: absolute;
	left: ${({ size }) =>
		Number.isFinite(size) ? size : additionalTagPosition[size]}px;
	bottom: ${({ size }) =>
		Number.isFinite(size) ? size : additionalContainersPosition[size]}px;
	line-height: 0;
`;

// #####################################################
