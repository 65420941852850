import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { breakpoints } from "styles/grid";
import styled from "styled-components";
import Text from "components/typography/Text";
import { circleSizes, borderSizes } from "./OnlineCircle";

const getStatusTitleName = ({ online, status }) => {
	const { t } = useTranslation(["common"]);
	let result = "";
	if (status === 1 || online === 0) {
		switch (online) {
			case 1:
				result = t("common:status.onlineYellow");
				break;
			case 2:
				result = t("common:status.online");
				break;
			default:
		}
	} else {
		switch (status) {
			case 2:
				result = t("common:status.meet");
				break;
			case 3:
				result = t("common:status.invisiable");
				break;
			default:
		}
	}
	return result;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledTag = styled(({ status, online, ...props }) => <Text {...props} />)`
	text-transform: uppercase;
	white-space: nowrap;

	@media (max-width: ${breakpoints.phone - 1}px) {
		font-size: 0.75rem;
		margin: 0 0.3rem;
	}

	color: ${({ status, online }) => {
		if (status === 1 || online === 0) {
			switch (online) {
				case 1:
					return "#ffcb18";
				case 2:
					return "#52c522";
				default:
					return null;
			}
		} else {
			switch (status) {
				case 2:
					return "#f54ae0";
				case 3:
					return "var(--dz-sys-icon-background-default)";
				default:
					return null;
			}
		}
	}};
`;

const CirclePadder = styled("div")`
	display: inline-block;
`;

const OnlineTagContainer = styled("div")`
	height: ${({ size, border }) =>
		circleSizes[size] + (border ? borderSizes[size] : 0)}px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	white-space: nowrap;

	background-color: rgba(0, 0, 0, 0.2);

	border-top-left-radius: 18px;
	border-bottom-left-radius: 18px;
	border-top-right-radius: ${({ theme: { borderRadius } }) =>
		borderRadius.panel};
	border-bottom-right-radius: ${({ theme: { borderRadius } }) =>
		borderRadius.panel};
	color: ${({ theme: { colors } }) => colors.whiteTrue};
	z-index: 1;

	${CirclePadder} {
		height: ${({ size, border }) =>
			circleSizes[size] + (border ? borderSizes[size] : 0)}px;
		width: ${({ size, border }) =>
			circleSizes[size] + (border ? borderSizes[size] : 0)}px;
	}
`;

const OnlineTag = ({ size, online, status, border, ...props }) => {
	return (
		<OnlineTagContainer size={size} border={border} {...props}>
			<CirclePadder />
			<StyledTag status={status} online={online} size="1rem" bold mx={2}>
				{getStatusTitleName({ online, status })}
			</StyledTag>
		</OnlineTagContainer>
	);
};

OnlineTag.defaultProps = {
	online: 0,
	size: "medium",
};

OnlineTag.propTypes = {
	online: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

OnlineTag.displayName = "OnlineTag";

export default OnlineTag;
